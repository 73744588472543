import { gecko, reddit, telegram, x } from "../assets";



// NAV LINKS
export const navLink = [
      { title: 'Home', to: '#' },
      { title: 'Links', to: '/#socials' },
      { title: 'Contract', to: '/#contract' },
      { title: 'Tokenomics', to: '/#tokenomics' },
      { title: 'Chart', to: '/#chart' },
      { title: 'Buy Now', to: 'https://www.iguanadex.com/swap?outputCurrency=0x6988d1188F7c4542B05393E3b8fB15A2c5d49Ae0&chain=etherlink', external: true },
];

// socials
export const socials = [
      { img: x, url: 'https://x.com/peeptoken_' },
      { img: telegram, url: 'https://t.me/peeptoken' },
      { img: reddit, url: 'https://www.reddit.com/r/Peepcryptocurrency' },
      { img: gecko, url: 'https://www.geckoterminal.com/etherlink/pools/0x6988d1188F7c4542B05393E3b8fB15A2c5d49Ae0' },
];

// tokenomics

export const tokenomics = [
      {
            name: '420.69T Supply',
            token: ''
      },
      {
            name: '0 Taxes',
            token: ''
      },
      {
            name: 'LP Burned',
            token: ''
      },
      {
            name: 'Ownership Renounced',
            token: ''
      },

];
