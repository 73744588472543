import styled from "styled-components";

const Features = () => {
      return (
            <>
                  <div id="chart"></div>
                  <StyledTestimonial
                        data-aos="zoom-in"
                        data-aos-duration="500"
                  >

                        <div className="container">

                              <div id="geckoterminal-embed"><iframe src="https://www.geckoterminal.com/etherlink/pools/0x6988d1188F7c4542B05393E3b8fB15A2c5d49Ae0?embed=1&info=1&swaps=1&grayscale=0&light_chart=0" title="GeckoTerminal Embed"></iframe></div>

                        </div>

                  </StyledTestimonial>
            </>
      );
};

export default Features;

const StyledTestimonial = styled.section`


      padding: 4rem 0;
      position: relative;

      #geckoterminal-embed {
            position:relative;
            width:100%;
            padding-bottom:125%;
      }
      
      @media(min-width:1400px){
            #geckoterminal-embed{
                  padding-bottom:65%;
            }
      }
      #geckoterminal-embed iframe{
            position:absolute;
            width:100%;
            height:100%;
            top:0;
            left:0;
            border:0;
      }
   
    
            `;